/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BudgetData,
  OfferClusterData,
  OfferData,
  OfferDataV2,
  OfferPatch,
  PaginatedOfferDataV2,
  PaginatedProducts,
  ProductData,
  ProductDataV2,
  RequestBody,
  Response,
  UploadPicture,
  UploadPictureResponse,
  UploadPictureUrl,
} from '../models';
import {
    BudgetDataFromJSON,
    BudgetDataToJSON,
    OfferClusterDataFromJSON,
    OfferClusterDataToJSON,
    OfferDataFromJSON,
    OfferDataToJSON,
    OfferDataV2FromJSON,
    OfferDataV2ToJSON,
    OfferPatchFromJSON,
    OfferPatchToJSON,
    PaginatedOfferDataV2FromJSON,
    PaginatedOfferDataV2ToJSON,
    PaginatedProductsFromJSON,
    PaginatedProductsToJSON,
    ProductDataFromJSON,
    ProductDataToJSON,
    ProductDataV2FromJSON,
    ProductDataV2ToJSON,
    RequestBodyFromJSON,
    RequestBodyToJSON,
    ResponseFromJSON,
    ResponseToJSON,
    UploadPictureFromJSON,
    UploadPictureToJSON,
    UploadPictureResponseFromJSON,
    UploadPictureResponseToJSON,
    UploadPictureUrlFromJSON,
    UploadPictureUrlToJSON,
} from '../models';

export interface BackendApiBudgetGetRequest {
    id: string;
}

export interface BackendApiBudgetPutRequest {
    id: string;
}

export interface BackendApiCloudStorageListFilesRequest {
    requestBody: RequestBody;
}

export interface BackendApiFeaturedGetRequest {
    id: string;
}

export interface BackendApiFeaturedUpdateRequest {
    id: string;
}

export interface BackendApiGetCustomerRequest {
    id: string;
}

export interface BackendApiGetCustomerPurchasesRequest {
    id: string;
}

export interface BackendApiGetCustomerWithdrawsRequest {
    id: string;
}

export interface BackendApiGetInvoiceByNumberRequest {
    number: string;
}

export interface BackendApiGetPurchaseRequest {
    id: string;
}

export interface BackendApiGetPurchaseByNumberRequest {
    number: string;
}

export interface BackendApiInvoiceForceRetryRequest {
    number: string;
}

export interface BackendApiOfferGetRequest {
    id: string;
}

export interface BackendApiOfferListRequest {
    date?: string;
}

export interface BackendApiOfferNewListRequest {
    offerData: Array<OfferData>;
}

export interface BackendApiProductGetRequest {
    id: string;
}

export interface BackendApiProductListRequest {
    sort?: ProductListSortEnum;
    page?: number;
    size?: number;
}

export interface BackendApiProductNewRequest {
    productData: ProductData;
}

export interface BackendApiProductUpdateRequest {
    id: string;
    productData: ProductData;
}

export interface BackendApiPublicStorageUploadPictureRequest {
    uploadPicture: UploadPicture;
}

export interface BackendApiPublicStorageUploadPictureUrlRequest {
    uploadPictureUrl: UploadPictureUrl;
}

export interface BackendApiRetryPurchaseRequest {
    id: string;
}

export interface BackendApiV2OfferCreateV2Request {
    offerDataV2: OfferDataV2;
}

export interface BackendApiV2OfferDeleteV2Request {
    id: string;
    details: string;
}

export interface BackendApiV2OfferGetV2Request {
    id: string;
}

export interface BackendApiV2OfferListPagesRequest {
    productQuery?: string;
    size?: number;
    page?: number;
    datetime?: Date;
    visible?: boolean;
    published?: boolean;
}

export interface BackendApiV2OfferListV2Request {
    size?: number;
    page?: number;
    datetime?: Date;
    visible?: boolean;
    published?: boolean;
}

export interface BackendApiV2OfferUpdateV2Request {
    offerPatch: OfferPatch;
}

export interface BackendApiV2ProductCreateV2Request {
    productDataV2: ProductDataV2;
}

export interface BackendApiV2ProductFindOrFetchRequest {
    ean: number;
    category?: string;
}

export interface BackendApiV2ProductGetV2Request {
    id: string;
}

export interface BackendApiV2ProductListV2Request {
    page?: number;
    size?: number;
}

export interface BackendApiV2ProductUpdateV2Request {
    productDataV2: ProductDataV2;
}

/**
 * 
 */
export class BackendApi extends runtime.BaseAPI {

    /**
     */
    async budgetGetRaw(requestParameters: BackendApiBudgetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling budgetGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/budget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async budgetGet(requestParameters: BackendApiBudgetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.budgetGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async budgetListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BudgetData>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/budget/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BudgetDataFromJSON));
    }

    /**
     */
    async budgetList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BudgetData>> {
        const response = await this.budgetListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetNewRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/budget/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async budgetNew(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.budgetNewRaw(initOverrides);
    }

    /**
     */
    async budgetPutRaw(requestParameters: BackendApiBudgetPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling budgetPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/budget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async budgetPut(requestParameters: BackendApiBudgetPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.budgetPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async cloudStorageDownloadFileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/cloud-storage/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cloudStorageDownloadFile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cloudStorageDownloadFileRaw(initOverrides);
    }

    /**
     */
    async cloudStorageListFilesRaw(requestParameters: BackendApiCloudStorageListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Response>>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling cloudStorageListFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/cloud-storage/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestBodyToJSON(requestParameters.requestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResponseFromJSON));
    }

    /**
     */
    async cloudStorageListFiles(requestParameters: BackendApiCloudStorageListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Response>> {
        const response = await this.cloudStorageListFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clusterListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OfferClusterData>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/cluster/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferClusterDataFromJSON));
    }

    /**
     */
    async clusterList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OfferClusterData>> {
        const response = await this.clusterListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async featuredGetRaw(requestParameters: BackendApiFeaturedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling featuredGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/featured/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async featuredGet(requestParameters: BackendApiFeaturedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.featuredGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async featuredListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/featured/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async featuredList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.featuredListRaw(initOverrides);
    }

    /**
     */
    async featuredNewRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/featured/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async featuredNew(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.featuredNewRaw(initOverrides);
    }

    /**
     */
    async featuredUpdateRaw(requestParameters: BackendApiFeaturedUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling featuredUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/featured/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async featuredUpdate(requestParameters: BackendApiFeaturedUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.featuredUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCustomerRaw(requestParameters: BackendApiGetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/customer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getCustomer(requestParameters: BackendApiGetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getCustomerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCustomerPurchasesRaw(requestParameters: BackendApiGetCustomerPurchasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomerPurchases.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/customer/{id}/purchases`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getCustomerPurchases(requestParameters: BackendApiGetCustomerPurchasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getCustomerPurchasesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCustomerWithdrawsRaw(requestParameters: BackendApiGetCustomerWithdrawsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomerWithdraws.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/customer/{id}/withdrawals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getCustomerWithdraws(requestParameters: BackendApiGetCustomerWithdrawsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getCustomerWithdrawsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getInvoiceByNumberRaw(requestParameters: BackendApiGetInvoiceByNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.number === null || requestParameters.number === undefined) {
            throw new runtime.RequiredError('number','Required parameter requestParameters.number was null or undefined when calling getInvoiceByNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/invoice/number/{number}`.replace(`{${"number"}}`, encodeURIComponent(String(requestParameters.number))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getInvoiceByNumber(requestParameters: BackendApiGetInvoiceByNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getInvoiceByNumberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getPurchaseRaw(requestParameters: BackendApiGetPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/purchase/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getPurchase(requestParameters: BackendApiGetPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getPurchaseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getPurchaseByNumberRaw(requestParameters: BackendApiGetPurchaseByNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.number === null || requestParameters.number === undefined) {
            throw new runtime.RequiredError('number','Required parameter requestParameters.number was null or undefined when calling getPurchaseByNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/purchase/number/{number}`.replace(`{${"number"}}`, encodeURIComponent(String(requestParameters.number))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getPurchaseByNumber(requestParameters: BackendApiGetPurchaseByNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getPurchaseByNumberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async invoiceForceRetryRaw(requestParameters: BackendApiInvoiceForceRetryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.number === null || requestParameters.number === undefined) {
            throw new runtime.RequiredError('number','Required parameter requestParameters.number was null or undefined when calling invoiceForceRetry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/invoice/number/{number}/force-retry`.replace(`{${"number"}}`, encodeURIComponent(String(requestParameters.number))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async invoiceForceRetry(requestParameters: BackendApiInvoiceForceRetryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoiceForceRetryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async loginGetCustomerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/public/backoffice-bff/login/callback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async loginGetCustomer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.loginGetCustomerRaw(initOverrides);
    }

    /**
     */
    async offerGetRaw(requestParameters: BackendApiOfferGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferData>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling offerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/offer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferDataFromJSON(jsonValue));
    }

    /**
     */
    async offerGet(requestParameters: BackendApiOfferGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferData> {
        const response = await this.offerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async offerListRaw(requestParameters: BackendApiOfferListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OfferData>>> {
        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/offer/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferDataFromJSON));
    }

    /**
     */
    async offerList(requestParameters: BackendApiOfferListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OfferData>> {
        const response = await this.offerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async offerNewListRaw(requestParameters: BackendApiOfferNewListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.offerData === null || requestParameters.offerData === undefined) {
            throw new runtime.RequiredError('offerData','Required parameter requestParameters.offerData was null or undefined when calling offerNewList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/offer/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.offerData.map(OfferDataToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async offerNewList(requestParameters: BackendApiOfferNewListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.offerNewListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productGetRaw(requestParameters: BackendApiProductGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductData>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDataFromJSON(jsonValue));
    }

    /**
     */
    async productGet(requestParameters: BackendApiProductGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductData> {
        const response = await this.productGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productListRaw(requestParameters: BackendApiProductListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedProducts>> {
        const queryParameters: any = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/product`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProductsFromJSON(jsonValue));
    }

    /**
     */
    async productList(requestParameters: BackendApiProductListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedProducts> {
        const response = await this.productListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productNewRaw(requestParameters: BackendApiProductNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productData === null || requestParameters.productData === undefined) {
            throw new runtime.RequiredError('productData','Required parameter requestParameters.productData was null or undefined when calling productNew.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/product/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDataToJSON(requestParameters.productData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productNew(requestParameters: BackendApiProductNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.productNewRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productUpdateRaw(requestParameters: BackendApiProductUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productUpdate.');
        }

        if (requestParameters.productData === null || requestParameters.productData === undefined) {
            throw new runtime.RequiredError('productData','Required parameter requestParameters.productData was null or undefined when calling productUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDataToJSON(requestParameters.productData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productUpdate(requestParameters: BackendApiProductUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.productUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async publicStorageUploadPictureRaw(requestParameters: BackendApiPublicStorageUploadPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadPictureResponse>> {
        if (requestParameters.uploadPicture === null || requestParameters.uploadPicture === undefined) {
            throw new runtime.RequiredError('uploadPicture','Required parameter requestParameters.uploadPicture was null or undefined when calling publicStorageUploadPicture.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/public-storage/uploadPicture`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadPictureToJSON(requestParameters.uploadPicture),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadPictureResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicStorageUploadPicture(requestParameters: BackendApiPublicStorageUploadPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadPictureResponse> {
        const response = await this.publicStorageUploadPictureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publicStorageUploadPictureUrlRaw(requestParameters: BackendApiPublicStorageUploadPictureUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadPictureResponse>> {
        if (requestParameters.uploadPictureUrl === null || requestParameters.uploadPictureUrl === undefined) {
            throw new runtime.RequiredError('uploadPictureUrl','Required parameter requestParameters.uploadPictureUrl was null or undefined when calling publicStorageUploadPictureUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/public-storage/uploadPictureUrl`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadPictureUrlToJSON(requestParameters.uploadPictureUrl),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadPictureResponseFromJSON(jsonValue));
    }

    /**
     */
    async publicStorageUploadPictureUrl(requestParameters: BackendApiPublicStorageUploadPictureUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadPictureResponse> {
        const response = await this.publicStorageUploadPictureUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseCreateChallengeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/purchase/create-challenge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaseCreateChallenge(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseCreateChallengeRaw(initOverrides);
    }

    /**
     */
    async retryPurchaseRaw(requestParameters: BackendApiRetryPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retryPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/purchase/{id}/retry`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async retryPurchase(requestParameters: BackendApiRetryPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.retryPurchaseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sentrySentryTunnelRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/public/backoffice-bff/sentry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sentrySentryTunnel(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sentrySentryTunnelRaw(initOverrides);
    }

    /**
     */
    async v2OfferCreateV2Raw(requestParameters: BackendApiV2OfferCreateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferDataV2>> {
        if (requestParameters.offerDataV2 === null || requestParameters.offerDataV2 === undefined) {
            throw new runtime.RequiredError('offerDataV2','Required parameter requestParameters.offerDataV2 was null or undefined when calling v2OfferCreateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/v2/offer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferDataV2ToJSON(requestParameters.offerDataV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferDataV2FromJSON(jsonValue));
    }

    /**
     */
    async v2OfferCreateV2(requestParameters: BackendApiV2OfferCreateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferDataV2> {
        const response = await this.v2OfferCreateV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2OfferDeleteV2Raw(requestParameters: BackendApiV2OfferDeleteV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferDataV2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v2OfferDeleteV2.');
        }

        if (requestParameters.details === null || requestParameters.details === undefined) {
            throw new runtime.RequiredError('details','Required parameter requestParameters.details was null or undefined when calling v2OfferDeleteV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.details !== undefined) {
            queryParameters['details'] = requestParameters.details;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/v2/offer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferDataV2FromJSON(jsonValue));
    }

    /**
     */
    async v2OfferDeleteV2(requestParameters: BackendApiV2OfferDeleteV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferDataV2> {
        const response = await this.v2OfferDeleteV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2OfferGetV2Raw(requestParameters: BackendApiV2OfferGetV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferDataV2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v2OfferGetV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/v2/offer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferDataV2FromJSON(jsonValue));
    }

    /**
     */
    async v2OfferGetV2(requestParameters: BackendApiV2OfferGetV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferDataV2> {
        const response = await this.v2OfferGetV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2OfferListPagesRaw(requestParameters: BackendApiV2OfferListPagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOfferDataV2>> {
        const queryParameters: any = {};

        if (requestParameters.productQuery !== undefined) {
            queryParameters['productQuery'] = requestParameters.productQuery;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.datetime !== undefined) {
            queryParameters['datetime'] = (requestParameters.datetime as any).toISOString();
        }

        if (requestParameters.visible !== undefined) {
            queryParameters['visible'] = requestParameters.visible;
        }

        if (requestParameters.published !== undefined) {
            queryParameters['published'] = requestParameters.published;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/v2/offer/paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOfferDataV2FromJSON(jsonValue));
    }

    /**
     */
    async v2OfferListPages(requestParameters: BackendApiV2OfferListPagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOfferDataV2> {
        const response = await this.v2OfferListPagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2OfferListV2Raw(requestParameters: BackendApiV2OfferListV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OfferDataV2>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.datetime !== undefined) {
            queryParameters['datetime'] = (requestParameters.datetime as any).toISOString();
        }

        if (requestParameters.visible !== undefined) {
            queryParameters['visible'] = requestParameters.visible;
        }

        if (requestParameters.published !== undefined) {
            queryParameters['published'] = requestParameters.published;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/v2/offer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferDataV2FromJSON));
    }

    /**
     */
    async v2OfferListV2(requestParameters: BackendApiV2OfferListV2Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OfferDataV2>> {
        const response = await this.v2OfferListV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2OfferUpdateV2Raw(requestParameters: BackendApiV2OfferUpdateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferPatch>> {
        if (requestParameters.offerPatch === null || requestParameters.offerPatch === undefined) {
            throw new runtime.RequiredError('offerPatch','Required parameter requestParameters.offerPatch was null or undefined when calling v2OfferUpdateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/v2/offer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferPatchToJSON(requestParameters.offerPatch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferPatchFromJSON(jsonValue));
    }

    /**
     */
    async v2OfferUpdateV2(requestParameters: BackendApiV2OfferUpdateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferPatch> {
        const response = await this.v2OfferUpdateV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2ProductCreateV2Raw(requestParameters: BackendApiV2ProductCreateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDataV2>> {
        if (requestParameters.productDataV2 === null || requestParameters.productDataV2 === undefined) {
            throw new runtime.RequiredError('productDataV2','Required parameter requestParameters.productDataV2 was null or undefined when calling v2ProductCreateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/v2/product`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDataV2ToJSON(requestParameters.productDataV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDataV2FromJSON(jsonValue));
    }

    /**
     */
    async v2ProductCreateV2(requestParameters: BackendApiV2ProductCreateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDataV2> {
        const response = await this.v2ProductCreateV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2ProductFindOrFetchRaw(requestParameters: BackendApiV2ProductFindOrFetchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDataV2>>> {
        if (requestParameters.ean === null || requestParameters.ean === undefined) {
            throw new runtime.RequiredError('ean','Required parameter requestParameters.ean was null or undefined when calling v2ProductFindOrFetch.');
        }

        const queryParameters: any = {};

        if (requestParameters.ean !== undefined) {
            queryParameters['ean'] = requestParameters.ean;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/v2/product/findOrFetch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDataV2FromJSON));
    }

    /**
     */
    async v2ProductFindOrFetch(requestParameters: BackendApiV2ProductFindOrFetchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDataV2>> {
        const response = await this.v2ProductFindOrFetchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2ProductGetV2Raw(requestParameters: BackendApiV2ProductGetV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDataV2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v2ProductGetV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/v2/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDataV2FromJSON(jsonValue));
    }

    /**
     */
    async v2ProductGetV2(requestParameters: BackendApiV2ProductGetV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDataV2> {
        const response = await this.v2ProductGetV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2ProductListV2Raw(requestParameters: BackendApiV2ProductListV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDataV2>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backoffice-bff/v2/product`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDataV2FromJSON));
    }

    /**
     */
    async v2ProductListV2(requestParameters: BackendApiV2ProductListV2Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDataV2>> {
        const response = await this.v2ProductListV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v2ProductUpdateV2Raw(requestParameters: BackendApiV2ProductUpdateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDataV2>> {
        if (requestParameters.productDataV2 === null || requestParameters.productDataV2 === undefined) {
            throw new runtime.RequiredError('productDataV2','Required parameter requestParameters.productDataV2 was null or undefined when calling v2ProductUpdateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/backoffice-bff/v2/product`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDataV2ToJSON(requestParameters.productDataV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDataV2FromJSON(jsonValue));
    }

    /**
     */
    async v2ProductUpdateV2(requestParameters: BackendApiV2ProductUpdateV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDataV2> {
        const response = await this.v2ProductUpdateV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ProductListSortEnum = {
    Desc: 'createdAt.desc',
    Asc: 'createdAt.asc'
} as const;
export type ProductListSortEnum = typeof ProductListSortEnum[keyof typeof ProductListSortEnum];
