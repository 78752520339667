import Card, { CardBody } from 'components/legacy/Card'
import React from 'react'

const FallbackPage: React.FC = () => {
  return <div className={'container-fluid page-wrapper'}>
    <div className='row h-100'>
      <div className='col-lg-6'>
        <Card stretch>
          <CardBody>
            <div/>
          </CardBody>
        </Card>
      </div>
      <div className='col-lg-6'>
        <Card stretch='semi'>
          <CardBody>
            <div/>
          </CardBody>
        </Card>
        <Card stretch='semi'>
          <CardBody>
            <div/>
          </CardBody>
        </Card>
      </div>
    </div>
  </div>
}

export default FallbackPage
