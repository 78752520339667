/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationData
 */
export interface PaginationData {
    /**
     * 
     * @type {number}
     * @memberof PaginationData
     */
    currentPage: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationData
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationData
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationData
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationData
     */
    totalElements: number;
}

/**
 * Check if a given object implements the PaginationData interface.
 */
export function instanceOfPaginationData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentPage" in value;
    isInstance = isInstance && "pageSize" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "totalPages" in value;
    isInstance = isInstance && "totalElements" in value;

    return isInstance;
}

export function PaginationDataFromJSON(json: any): PaginationData {
    return PaginationDataFromJSONTyped(json, false);
}

export function PaginationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPage': json['currentPage'],
        'pageSize': json['pageSize'],
        'count': json['count'],
        'totalPages': json['totalPages'],
        'totalElements': json['totalElements'],
    };
}

export function PaginationDataToJSON(value?: PaginationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPage': value.currentPage,
        'pageSize': value.pageSize,
        'count': value.count,
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
    };
}

