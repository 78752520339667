/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CashbackAmountV2 } from './CashbackAmountV2';
import {
    CashbackAmountV2FromJSON,
    CashbackAmountV2FromJSONTyped,
    CashbackAmountV2ToJSON,
} from './CashbackAmountV2';

/**
 * 
 * @export
 * @interface OfferDataV2
 */
export interface OfferDataV2 {
    /**
     * 
     * @type {string}
     * @memberof OfferDataV2
     */
    id?: string;
    /**
     * 
     * @type {CashbackAmountV2}
     * @memberof OfferDataV2
     */
    cashbackAmount: CashbackAmountV2;
    /**
     * 
     * @type {number}
     * @memberof OfferDataV2
     */
    maximumAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OfferDataV2
     */
    currentUnitPrice: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDataV2
     */
    useScore: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDataV2
     */
    published: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDataV2
     */
    visible: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferDataV2
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDataV2
     */
    dueDate: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDataV2
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDataV2
     */
    budgetId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferDataV2
     */
    clusterIds?: Array<string>;
}

/**
 * Check if a given object implements the OfferDataV2 interface.
 */
export function instanceOfOfferDataV2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cashbackAmount" in value;
    isInstance = isInstance && "maximumAmount" in value;
    isInstance = isInstance && "currentUnitPrice" in value;
    isInstance = isInstance && "useScore" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "visible" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "dueDate" in value;
    isInstance = isInstance && "productId" in value;

    return isInstance;
}

export function OfferDataV2FromJSON(json: any): OfferDataV2 {
    return OfferDataV2FromJSONTyped(json, false);
}

export function OfferDataV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferDataV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cashbackAmount': CashbackAmountV2FromJSON(json['cashbackAmount']),
        'maximumAmount': json['maximumAmount'],
        'currentUnitPrice': json['currentUnitPrice'],
        'useScore': json['useScore'],
        'published': json['published'],
        'visible': json['visible'],
        'startDate': json['startDate'],
        'dueDate': json['dueDate'],
        'productId': json['productId'],
        'budgetId': !exists(json, 'budgetId') ? undefined : json['budgetId'],
        'clusterIds': !exists(json, 'clusterIds') ? undefined : json['clusterIds'],
    };
}

export function OfferDataV2ToJSON(value?: OfferDataV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cashbackAmount': CashbackAmountV2ToJSON(value.cashbackAmount),
        'maximumAmount': value.maximumAmount,
        'currentUnitPrice': value.currentUnitPrice,
        'useScore': value.useScore,
        'published': value.published,
        'visible': value.visible,
        'startDate': value.startDate,
        'dueDate': value.dueDate,
        'productId': value.productId,
        'budgetId': value.budgetId,
        'clusterIds': value.clusterIds,
    };
}

