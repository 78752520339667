import React from 'react'
import { Helmet } from 'react-helmet'
import Button from '../components/legacy/Button'

const Page404: React.FC = () => {
  return <div className={'container-fluid page-wrapper'}>
        <Helmet>
            <title>Not Found</title>
        </Helmet>
        <div className='row d-flex align-items-center h-100'>
            <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                <div
                    className='text-primary fw-bold'
                    style={{ fontSize: 'calc(3rem + 3vw)' }}>
                    404
                </div>
                <div
                    className='text-dark fw-bold'
                    style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
                    Page not found
                </div>
            </div>
            <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                <Button
                    className='px-5 py-3'
                    color='primary'
                    isLight
                    icon='HolidayVillage'
                    tag='a'
                    href='/'>
                    Homepage
                </Button>
            </div>
        </div>
    </div>
}

export default Page404
