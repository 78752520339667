import React from 'react'
import App from './App'
import { app, auth, FirebaseAppContext, functions } from './firebase-app.config'
import { createRoot } from 'react-dom/client'
import './styles.scss'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <FirebaseAppContext.Provider value={{ app, functions, auth }}>
      <App/>
    </FirebaseAppContext.Provider>
  </React.StrictMode>
)
