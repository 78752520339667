import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut as firebaseSignOut } from 'firebase/auth'

export const getCurrentToken = async (): Promise<string | undefined> => {
  const auth = getAuth()
  const user = auth.currentUser
  const idTokenResult = await user?.getIdTokenResult()

  return idTokenResult?.token
}

interface AuthContextData {
  name: string | null
  loading: boolean
  isLoggedIn: boolean
  isAdmin: boolean
  signIn: () => Promise<void>
  signOut: () => Promise<void>
}

interface AuthState {
  name: string | null
  isLoggedIn: boolean
  isAdmin: boolean
}

const AuthContext = createContext<AuthContextData>({} as unknown as AuthContextData)

interface Props {
  children: JSX.Element
}

const AuthUserProvider = ({ children }: Props): JSX.Element => {
  const [data, setData] = useState<AuthState>({ name: null, isAdmin: false, isLoggedIn: false })

  const auth = useMemo(() => getAuth(), [])
  const [initializing, setInitializing] = useState(true)

  const signIn = async (): Promise<void> => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    await signInWithPopup(auth, provider)
  }

  const signOut = async (): Promise<void> => {
    await firebaseSignOut(auth)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setData({ name: null, isAdmin: false, isLoggedIn: false })
        setInitializing(false)
        return
      }
      user.getIdTokenResult(true).then((token) => {
        if (token && token.claims.role === 'admin') {
          setData({ name: user.displayName, isAdmin: true, isLoggedIn: true })
        } else {
          signOut()
        }
        setInitializing(false)
      })
    })
    return unsubscribe
  }, [auth])

  return (
        <AuthContext.Provider
            value={{
              name: data.name,
              isLoggedIn: data.isLoggedIn,
              loading: initializing,
              isAdmin: data.isAdmin,
              signOut,
              signIn
            }}>
            {children}
        </AuthContext.Provider>
  )
}

function useAuth (): AuthContextData {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthUser')
  }

  return context
}

export { AuthUserProvider, useAuth }
