import React, { useContext, useRef } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import AsideBrand from 'components/layout/AsideBrand'
import AsideSection from 'components/layout/AsideSection'
import AsideUser from 'components/layout/AsideUser'
import ThemeContext from 'contexts/themeContext'
import Tooltips from 'components/legacy/Tooltips'
import useAsideTouch from 'hooks/useAsideTouch'
import { navSections, routes } from 'AppRoutes'

const Aside: React.FC = () => {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext)

  const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch()

  const constraintsRef = useRef(null)

  return (
    <>
      <motion.aside
        // @ts-expect-error
        style={asideStyle}
        className={classNames(
          'aside',
          { open: asideStatus },
          {
            'aside-touch-bar': hasTouchButton,
            'aside-touch-bar-close': !touchStatus && hasTouchButton,
            'aside-touch-bar-open': touchStatus && hasTouchButton
          }
        )}>
        <div className='aside-head'>
          <AsideBrand asideStatus={asideStatus} setAsideStatus={setAsideStatus}/>
        </div>
        <div className='aside-body'>
          {Object.keys(navSections).map((id) => {
            return <div key={id} >
              <AsideSection
                title={navSections[id]}
                pages={routes.filter((page) => page.navLink?.navSectionId === id)}
              />
              <hr className="navigation-line"/>
            </div>
          }
          )}
        </div>
        <div className='aside-foot'>
          <AsideUser/>
        </div>
      </motion.aside>
      {asideStatus && hasTouchButton && (
        <>
          <motion.div className='aside-drag-area' ref={constraintsRef}/>
          <Tooltips title='Toggle Aside' flip={['top', 'right']}>
            <motion.div
              className='aside-touch'
              drag='x'
              whileDrag={{ scale: 1.2 }}
              whileHover={{ scale: 1.1 }}
              dragConstraints={constraintsRef}
              // onDrag={(event, info) => console.log(info.point.x, info.point.y)}
              dragElastic={0.1}
              style={{ x, zIndex: 1039 }}
              onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
            />
          </Tooltips>
        </>
      )}
    </>
  )
}

export default Aside
