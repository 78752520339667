import React from 'react'
import { Pages } from 'AppRoutes'
import Icon from 'components/legacy/Icon'
import { Link } from 'react-router-dom'

const AsideSection: React.FC<{ title: string, pages: Pages[] }> = (props) => {
  const { title, pages } = props
  return (
    <ul id="aside-campaigns" className="navigation">
      <li className="navigation-item">
        <span className="navigation-title">{title}</span>
      </li>
      { pages.map((page) => {
        if (!page.navLink) return null
        return <li key={page.urlPath} className="navigation-item">
          <Link className="navigation-link navigation-link-pill" to={page.urlPath}>
          <span className="navigation-link-info">
            <Icon className='navigation-icon' icon={page.navLink.icon}/>
            <span className="navigation-text">{page.navLink.name}</span>
          </span></Link>
        </li>
      })}
    </ul>
  )
}

export default AsideSection
