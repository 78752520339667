import React, { Context, createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useCookie } from 'react-use'
import { Buffer } from 'buffer'
import { BackendApi, Configuration } from 'clients/bff'
import * as Sentry from '@sentry/react'

type Fetcher = (path: string, options: Record<string, any>, fullUrl?: string) => Promise<any>

export const BackendContext = createContext<{
  token: string
  name: string
  picture: string
  fetcher: Fetcher
  plainFetcher: Fetcher
  blobFetcher: Fetcher
  api: BackendApi
  signOut: () => void
}>({
      api: new BackendApi(),
      token: '',
      name: '',
      picture: '',
      fetcher: async (path, options) => {
        return new Response()
      },
      plainFetcher: async (path, options) => {
        return new Response()
      },
      blobFetcher: async (path, options) => {
        return new Response()
      },
      signOut: () => null
    })

export const BackendProvider: React.FC<PropsWithChildren> = (props) => {
  const [cookieToken, setCookieToken] = useCookie('IAP_TOKEN')
  const [token, setToken] = useState(cookieToken)
  const [, setLocation] = useCookie('LOGIN_LOCATION')

  const backendHost = 'https://api.appengine.dinerama.com.br/backoffice-bff'
  useEffect(() => {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/login/callback`
    const loginPage = `https://accounts.google.com/o/oauth2/v2/auth?client_id=749349398914-aev1hvuvro6s5he2r0d2ka4o63fis20d.apps.googleusercontent.com&response_type=code&scope=openid%20email%20profile&access_type=offline&cred_ref=true&redirect_uri=${redirectUrl}`
    if (token == null && window.location.pathname !== '/login/callback') {
      setLocation(window.location.href)
      window.location.assign(loginPage)
    }
  }, [token]
  )
  const userData = Buffer.from(token?.split('.')[1] ?? '', 'base64').toString('utf-8')
  const user = JSON.parse((userData !== '') ? userData : '{}')
  const signOut = (): void => {
    setCookieToken('')
    setToken(null)
  }
  const fetcher: (usePlain: boolean, useBlob: boolean) => Fetcher = (usePlain, useBlob) => async (path, options = {}, fullUrl = '') => {
    const authToken = `Bearer ${token ?? ''}`
    if (options) {
      options.headers = options.headers ?? {}
      options.headers.Authorization = authToken
    }
    const url = fullUrl || backendHost + path
    return fetch(
      url,
      options
    ).then(async (r) => {
      if (r.status === 401 || r.status === 403) {
        setToken(null)
        throw await r.json()
      } else if (r.status >= 300) {
        throw await r.json()
      }
      if (useBlob) {
        return await r.blob()
      } else if (usePlain) {
        return await r.text()
      } else {
        return await r.json()
      }
    })
  }
  const config = new Configuration({ basePath: 'https://api.appengine.dinerama.com.br', headers: { ...(token) ? { Authorization: `Bearer ${token}` } : {} } })
  Sentry.setUser({ email: user.email, username: user.name })
  return <BackendContext.Provider value={{
    signOut,
    token: token ?? '',
    name: user.name ?? '',
    picture: user.picture ?? '' ,
    api: new BackendApi(config),
    fetcher: fetcher(false, false),
    plainFetcher: fetcher(true, false),
    blobFetcher: fetcher(true, true)
  }}>
    {props.children}
  </BackendContext.Provider>
}

export const useBackend = (): typeof BackendContext extends Context<infer R> ? R : never => {
  return useContext(BackendContext)
}
