/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestBody
 */
export interface RequestBody {
    /**
     * 
     * @type {string}
     * @memberof RequestBody
     */
    prefix: string;
}

/**
 * Check if a given object implements the RequestBody interface.
 */
export function instanceOfRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "prefix" in value;

    return isInstance;
}

export function RequestBodyFromJSON(json: any): RequestBody {
    return RequestBodyFromJSONTyped(json, false);
}

export function RequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prefix': json['prefix'],
    };
}

export function RequestBodyToJSON(value?: RequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefix': value.prefix,
    };
}

