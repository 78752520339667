/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadPicture
 */
export interface UploadPicture {
    /**
     * 
     * @type {string}
     * @memberof UploadPicture
     */
    base64Data: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadPicture
     */
    resize?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadPicture
     */
    removeBackground?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UploadPicture
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof UploadPicture
     */
    width: number;
}

/**
 * Check if a given object implements the UploadPicture interface.
 */
export function instanceOfUploadPicture(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "base64Data" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "width" in value;

    return isInstance;
}

export function UploadPictureFromJSON(json: any): UploadPicture {
    return UploadPictureFromJSONTyped(json, false);
}

export function UploadPictureFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadPicture {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base64Data': json['base64Data'],
        'resize': !exists(json, 'resize') ? undefined : json['resize'],
        'removeBackground': !exists(json, 'removeBackground') ? undefined : json['removeBackground'],
        'height': json['height'],
        'width': json['width'],
    };
}

export function UploadPictureToJSON(value?: UploadPicture | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base64Data': value.base64Data,
        'resize': value.resize,
        'removeBackground': value.removeBackground,
        'height': value.height,
        'width': value.width,
    };
}

