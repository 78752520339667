/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CashbackAmount } from './CashbackAmount';
import {
    CashbackAmountFromJSON,
    CashbackAmountFromJSONTyped,
    CashbackAmountToJSON,
} from './CashbackAmount';
import type { ProductData } from './ProductData';
import {
    ProductDataFromJSON,
    ProductDataFromJSONTyped,
    ProductDataToJSON,
} from './ProductData';

/**
 * 
 * @export
 * @interface OfferData
 */
export interface OfferData {
    /**
     * 
     * @type {string}
     * @memberof OfferData
     */
    id?: string;
    /**
     * 
     * @type {CashbackAmount}
     * @memberof OfferData
     */
    cashbackAmount: CashbackAmount;
    /**
     * 
     * @type {number}
     * @memberof OfferData
     */
    maximumAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OfferData
     */
    currentUnitPrice: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfferData
     */
    useScore: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferData
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferData
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof OfferData
     */
    dueDate: string;
    /**
     * 
     * @type {ProductData}
     * @memberof OfferData
     */
    product: ProductData | null;
    /**
     * 
     * @type {string}
     * @memberof OfferData
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof OfferData
     */
    budgetId: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferData
     */
    clusters: Array<string>;
}

/**
 * Check if a given object implements the OfferData interface.
 */
export function instanceOfOfferData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cashbackAmount" in value;
    isInstance = isInstance && "maximumAmount" in value;
    isInstance = isInstance && "currentUnitPrice" in value;
    isInstance = isInstance && "useScore" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "dueDate" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "budgetId" in value;
    isInstance = isInstance && "clusters" in value;

    return isInstance;
}

export function OfferDataFromJSON(json: any): OfferData {
    return OfferDataFromJSONTyped(json, false);
}

export function OfferDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cashbackAmount': CashbackAmountFromJSON(json['cashbackAmount']),
        'maximumAmount': json['maximumAmount'],
        'currentUnitPrice': json['currentUnitPrice'],
        'useScore': json['useScore'],
        'published': json['published'],
        'startDate': json['startDate'],
        'dueDate': json['dueDate'],
        'product': ProductDataFromJSON(json['product']),
        'productId': json['productId'],
        'budgetId': json['budgetId'],
        'clusters': json['clusters'],
    };
}

export function OfferDataToJSON(value?: OfferData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cashbackAmount': CashbackAmountToJSON(value.cashbackAmount),
        'maximumAmount': value.maximumAmount,
        'currentUnitPrice': value.currentUnitPrice,
        'useScore': value.useScore,
        'published': value.published,
        'startDate': value.startDate,
        'dueDate': value.dueDate,
        'product': ProductDataToJSON(value.product),
        'productId': value.productId,
        'budgetId': value.budgetId,
        'clusters': value.clusters,
    };
}

