import React, { useContext } from 'react'
import classNames from 'classnames'
import ThemeContext from 'contexts/themeContext'

export const PresentationContainer: React.FC<React.PropsWithChildren & { className?: string }> = (props) => {
  const { rightPanel }: any = useContext(ThemeContext)
  const { children, className } = props
  return (
    <div
      className={classNames(
        'wrapper',
        { 'wrapper-right-panel-active': rightPanel },
        className
      )}
    >
      {children}
    </div>
  )
}

export default PresentationContainer
