/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferPatch
 */
export interface OfferPatch {
    /**
     * 
     * @type {string}
     * @memberof OfferPatch
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OfferPatch
     */
    currentUnitPrice: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfferPatch
     */
    published: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferPatch
     */
    visible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferPatch
     */
    useScore: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferPatch
     */
    budgetId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferPatch
     */
    clusterIds?: Array<string>;
}

/**
 * Check if a given object implements the OfferPatch interface.
 */
export function instanceOfOfferPatch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "currentUnitPrice" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "visible" in value;
    isInstance = isInstance && "useScore" in value;

    return isInstance;
}

export function OfferPatchFromJSON(json: any): OfferPatch {
    return OfferPatchFromJSONTyped(json, false);
}

export function OfferPatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferPatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'currentUnitPrice': json['currentUnitPrice'],
        'published': json['published'],
        'visible': json['visible'],
        'useScore': json['useScore'],
        'budgetId': !exists(json, 'budgetId') ? undefined : json['budgetId'],
        'clusterIds': !exists(json, 'clusterIds') ? undefined : json['clusterIds'],
    };
}

export function OfferPatchToJSON(value?: OfferPatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'currentUnitPrice': value.currentUnitPrice,
        'published': value.published,
        'visible': value.visible,
        'useScore': value.useScore,
        'budgetId': value.budgetId,
        'clusterIds': value.clusterIds,
    };
}

