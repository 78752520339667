import { BrowserRouter as Router, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import React, { Suspense, useEffect } from 'react'
import { SignIn } from './pages/authentication/SignIn'
import { ThemeContextProvider } from 'contexts/themeContext'
import { AuthUserProvider, useAuth } from 'pages/authentication/context'
import Aside from 'components/layout/Aside'
import PresentationContainer from 'components/layout/PresentationContainer'
import { BackendProvider } from 'contexts/backend'
import ContentRouter from 'components/layout/ContentRouter'
import Footer from 'components/layout/Footer'
import PresentationOverlay from 'components/layout/PresentationOverlay'
import { SWRConfig } from 'swr'
import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://61ddd745e3e8d49df0ffb8a31f6942cf@o4505765662031872.ingest.sentry.io/4505795316482048',
    tunnel: 'https://api.appengine.dinerama.com.br/public/backoffice-bff/sentry',
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api.appengine.dinerama.com.br\/backoffice-bff/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

function localStorageProvider (): Map<any, any> {
  const allValues: any[] = []
  let getIndex = 0
  while (true) {
    const cached = localStorage.getItem(`dinerama-cache-${getIndex}`)
    if (cached === null) break
    allValues.push(JSON.parse(cached))
    getIndex++
  }
  const map = new Map(allValues)
  window.addEventListener('beforeunload', () => {
    const items = Array.from(map.entries())
    let setIndex = items.length
    items.forEach((item, index) => localStorage.setItem(`dinerama-cache-${index}`, JSON.stringify(item)))
    while (true) {
      const cached = localStorage.getItem(`dinerama-cache-${setIndex}`)
      if (cached === null) break
      localStorage.removeItem(`dinerama-cache-${setIndex}`)
      setIndex++
    }
  })
  return map
}

const RoutesDelegator: React.FC = (): JSX.Element => {
  const { isLoggedIn, loading, isAdmin } = useAuth()

  if (loading) {
    return <Suspense/>
  }

  return (isLoggedIn && isAdmin ? <AuthenticatedArea/> : <SignIn/>)
}
function App (): JSX.Element {
  useEffect(() =>
    Sentry.configureScope((scope) => {
      scope.setTags({
        host: window.location.host
      })
    }), [])
  return (
    <SWRConfig value={{
      provider: localStorageProvider,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      keepPreviousData: true
    }}>
    <ThemeContextProvider>
      <AuthUserProvider>
          <BackendProvider>
            <RoutesDelegator/>
          </BackendProvider>
      </AuthUserProvider>
    </ThemeContextProvider>
    </SWRConfig>
  )
}

const AuthenticatedArea: React.FC = (): JSX.Element => {
  return (
    <Router>
      <div className='app'>
        <Aside/>
        <PresentationContainer>
          <ContentRouter/>
          <Footer/>
        </PresentationContainer>
        <PresentationOverlay/>
      </div>
    </Router>
  )
}

export default App
