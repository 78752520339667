/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BudgetData
 */
export interface BudgetData {
    /**
     * 
     * @type {string}
     * @memberof BudgetData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BudgetData
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BudgetData
     */
    offers: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BudgetData
     */
    amountUsed: number;
    /**
     * 
     * @type {number}
     * @memberof BudgetData
     */
    amountAvailable: number;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetData
     */
    active: boolean;
}

/**
 * Check if a given object implements the BudgetData interface.
 */
export function instanceOfBudgetData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "offers" in value;
    isInstance = isInstance && "amountUsed" in value;
    isInstance = isInstance && "amountAvailable" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function BudgetDataFromJSON(json: any): BudgetData {
    return BudgetDataFromJSONTyped(json, false);
}

export function BudgetDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'offers': json['offers'],
        'amountUsed': json['amountUsed'],
        'amountAvailable': json['amountAvailable'],
        'active': json['active'],
    };
}

export function BudgetDataToJSON(value?: BudgetData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'offers': value.offers,
        'amountUsed': value.amountUsed,
        'amountAvailable': value.amountAvailable,
        'active': value.active,
    };
}

