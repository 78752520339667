/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Dun } from './Dun';
import {
    DunFromJSON,
    DunFromJSONTyped,
    DunToJSON,
} from './Dun';

/**
 * 
 * @export
 * @interface ProductDataV2
 */
export interface ProductDataV2 {
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    ncm?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDataV2
     */
    pictures: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    size: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataV2
     */
    unitSize: string;
    /**
     * 
     * @type {Array<Dun>}
     * @memberof ProductDataV2
     */
    duns: Array<Dun>;
}

/**
 * Check if a given object implements the ProductDataV2 interface.
 */
export function instanceOfProductDataV2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "manufacturer" in value;
    isInstance = isInstance && "pictures" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "unitSize" in value;
    isInstance = isInstance && "duns" in value;

    return isInstance;
}

export function ProductDataV2FromJSON(json: any): ProductDataV2 {
    return ProductDataV2FromJSONTyped(json, false);
}

export function ProductDataV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDataV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'brand': json['brand'],
        'category': json['category'],
        'manufacturer': json['manufacturer'],
        'ncm': !exists(json, 'ncm') ? undefined : json['ncm'],
        'pictures': json['pictures'],
        'size': json['size'],
        'unitSize': json['unitSize'],
        'duns': ((json['duns'] as Array<any>).map(DunFromJSON)),
    };
}

export function ProductDataV2ToJSON(value?: ProductDataV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'brand': value.brand,
        'category': value.category,
        'manufacturer': value.manufacturer,
        'ncm': value.ncm,
        'pictures': value.pictures,
        'size': value.size,
        'unitSize': value.unitSize,
        'duns': ((value.duns as Array<any>).map(DunToJSON)),
    };
}

