import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import ThemeContext from 'contexts/themeContext'

const Portal: (props: React.PropsWithChildren<{ id?: string }>) => any = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, id } = props
  const { fullScreenStatus }: any = useContext(ThemeContext)
  const mount = document.getElementById(id ?? 'portal-root')
  if (fullScreenStatus) return <>{children}</>
  if (mount) return ReactDOM.createPortal(children, mount)
}

export default Portal
