// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'

import React from 'react'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCmeWigtg04S4iA9nrCZIXFxPd2VWLu-Ec',
  authDomain: 'dinerama-2912c.firebaseapp.com',
  databaseURL: 'https://dinerama-2912c-default-rtdb.firebaseio.com',
  projectId: 'dinerama-2912c',
  storageBucket: 'dinerama-2912c.appspot.com',
  messagingSenderId: '749349398914',
  appId: '1:749349398914:web:ad1661b2089eb15cb6479b',
  measurementId: 'G-20FQGX3NMM'
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const functions = getFunctions(app)
export const auth = getAuth()

export const FirebaseAppContext = React.createContext({ app, functions, auth })
