/* tslint:disable */
/* eslint-disable */
/**
 * Backoffice Backend-For-Frontend
 * Backoffice APIs!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OfferDataV2 } from './OfferDataV2';
import {
    OfferDataV2FromJSON,
    OfferDataV2FromJSONTyped,
    OfferDataV2ToJSON,
} from './OfferDataV2';
import type { PaginationData } from './PaginationData';
import {
    PaginationDataFromJSON,
    PaginationDataFromJSONTyped,
    PaginationDataToJSON,
} from './PaginationData';

/**
 * 
 * @export
 * @interface PaginatedOfferDataV2
 */
export interface PaginatedOfferDataV2 {
    /**
     * 
     * @type {Array<OfferDataV2>}
     * @memberof PaginatedOfferDataV2
     */
    entries: Array<OfferDataV2>;
    /**
     * 
     * @type {PaginationData}
     * @memberof PaginatedOfferDataV2
     */
    pagination: PaginationData;
}

/**
 * Check if a given object implements the PaginatedOfferDataV2 interface.
 */
export function instanceOfPaginatedOfferDataV2(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function PaginatedOfferDataV2FromJSON(json: any): PaginatedOfferDataV2 {
    return PaginatedOfferDataV2FromJSONTyped(json, false);
}

export function PaginatedOfferDataV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedOfferDataV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(OfferDataV2FromJSON)),
        'pagination': PaginationDataFromJSON(json['pagination']),
    };
}

export function PaginatedOfferDataV2ToJSON(value?: PaginatedOfferDataV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(OfferDataV2ToJSON)),
        'pagination': PaginationDataToJSON(value.pagination),
    };
}

