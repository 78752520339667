import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

const Footer: React.FC = () => {
  const root = document.documentElement
  const [, setLoading] = useState(localStorage.getItem('loading'))
  useEffect(() => { setTimeout(() => setLoading(localStorage.getItem('loading')), 1000) },[])
  root.style.setProperty('--footer-height', '10px')
  return (
    <footer className='footer'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <span className='fw-light'>Dinerama</span>
          </div>
          <div className='col-auto'>
            <a
              href='/'
              className={classNames('text-decoration-none', 'link-dark')}>
              <small className='fw-bold'>Página Inicial</small>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
