import React, { useState } from 'react'
import classNames from 'classnames'
import Collapse from 'components/legacy/Collapse'
import Icon from 'components/legacy/Icon'

import { useBackend } from 'contexts/backend'
import { useAuth } from 'pages/authentication/context'

const AsideUser: React.FC = () => {
  const [collapseStatus, setCollapseStatus] = useState(false)
  const { signOut, name, picture } = useBackend()
  const auth = useAuth()
  return (
    <>
      <div
        className={classNames('user', { open: collapseStatus })}
        role='presentation'
        onClick={() => setCollapseStatus(!collapseStatus)}>
        <div className='user-avatar'>
          <img
            src={picture}
            alt='Avatar'
            width={128}
            height={128}
          />
        </div>
        <div className='user-info'>
          <div className='user-name d-flex align-items-center'>
            {name ?? 'user'}
            <Icon icon='Verified' className='ms-1' color='info'/>
          </div>
        </div>
      </div>
      {/* @ts-expect-error */}
      <Collapse isOpen={collapseStatus} className='user-menu'>
        <nav aria-label='aside-bottom-user-menu-2'>
          <div className='navigation'>
            <div
              role='presentation'
              className='navigation-item cursor-pointer'
              onClick={() => { signOut(); auth.signOut() }}>
              <span className='navigation-link navigation-link-pill'>
                <span className='navigation-link-info'>
                <Icon icon='Logout' className='navigation-icon'/>
                <span className='navigation-text'>Logout</span>
                </span>
              </span>
            </div>
          </div>
        </nav>
      </Collapse>
    </>
  )
}

export default AsideUser
