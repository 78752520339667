import React from 'react'
import GoogleButton from './google-button.png'
import { useAuth } from './context'

export const SignIn: React.FC = (): JSX.Element => {
  const { isLoggedIn, isAdmin, signIn, signOut } = useAuth()
  const signOutWrapper = (): any => signOut()
  const signInWrapper = (): any => signIn()

  if (isLoggedIn && !isAdmin) {
    return (
       <div>
          <h1>Permission denied. You are not admin</h1>
          <a href="https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=/" onClick={signOutWrapper}>Sair da sua conta</a>
        </div>
    )
  }

  return (
    <div onClick={signInWrapper}>
      <div>
        <img src={GoogleButton} alt={'Login com Google'} />
      </div>
    </div>
  )
}
