import React from 'react'

interface Props {
  width: number
  height: number
}

const AsideLogo: React.FC<Props> = ({ width = 2155, height = 854 }) => {
  return (
  <svg
   width={width}
   height={height}
   viewBox='0 0 2155 854'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'>
   <circle cx='542' cy='542' r='542' fill='#FFD715'/>
   <rect
    width='373.793'
    height='89.385'
    x='590.59'
    y='663.728'
    fill='#000'
    rx='44.693'
    transform='rotate(-180 590.59 663.728)'
   />
   <rect
    width='373.793'
    height='89.385'
    x='590.59'
    y='515.842'
    fill='#000'
    rx='44.693'
    transform='rotate(-180 590.59 515.842)'
   />
   <path
    fill='#000'
    d='M563.393 873.376c200.124 0 321.353-123.749 321.353-328.929 0-204.539-121.229-327.647-319.429-327.647h-213.08c-11.974 0-21.68 9.707-21.68 21.68v613.216c0 11.974 9.706 21.68 21.68 21.68h211.156zm-83.128-118.94c-5.987 0-10.84-4.854-10.84-10.84V346.581c0-5.987 4.853-10.84 10.84-10.84h77.676c123.153 0 188.257 62.836 188.257 208.706 0 146.511-65.104 209.989-188.578 209.989h-77.355z'
   />
  </svg>
  )
}

export default AsideLogo
