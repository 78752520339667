import { lazy } from 'react'

export const navSections: { [id: string]: string } = {
  operations: 'Operações',
  campaigns: 'Campanhas',
  payments: 'Pagamentos'
}

type NavLink = {
  navSectionId: keyof typeof navSections
  name: string
  icon: string
}

export type Pages = {
  urlPath: string
  component: React.LazyExoticComponent<React.ComponentType<any>>
  navLink?: NavLink
}

export const routes: Pages[] = [
  {
    urlPath: '/login/callback',
    component: lazy(async () => import('pages/authentication/callback'))
  },
  {
    urlPath: '/offers/new',
    component: lazy(async () => import('pages/campaigns/OfferNew'))
  },
  {
    urlPath: '/offers/publish',
    component: lazy(async () => import('pages/campaigns/OfferPublish'))
  },
  {
    urlPath: '/offers/:id',
    component: lazy(async () => import('pages/campaigns/OfferUpdate'))
  },
  {
    urlPath: '/products/:id',
    component: lazy(async () => import('pages/campaigns/ProductPage'))
  },
  {
    urlPath: '/purchase/:id',
    component: lazy(async () => import('pages/operations/PurchasePage'))
  },
  {
    urlPath: '/challenge/:id',
    component: lazy(async () => import('pages/operations/ChallengePage'))
  },
  {
    urlPath: '/customer/:id',
    component: lazy(async () => import('pages/operations/CustomerPage'))
  },
  {
    urlPath: '/budget/new',
    component: lazy(async () => import('pages/campaigns/BudgetNew'))
  },
  {
    urlPath: '/featured/new',
    component: lazy(async () => import('pages/campaigns/FeaturedNew'))
  },
  {
    urlPath: '/',
    component: lazy(async () => import('pages/campaigns/OfferList'))
  },
  {
    urlPath: '/products',
    component: lazy(async () => import('pages/campaigns/ProductsPage')),
    navLink: {
      navSectionId: 'campaigns',
      name: 'Produtos',
      icon: 'Basket'
    }
  },
  {
    urlPath: '/offers',
    component: lazy(async () => import('pages/campaigns/OfferList')),
    navLink: {
      navSectionId: 'campaigns',
      name: 'Ofertas',
      icon: 'LocalOffer'
    }
  },
  {
    urlPath: '/budget/list',
    component: lazy(async () => import('pages/campaigns/BudgetList')),
    navLink: {
      navSectionId: 'campaigns',
      name: 'Budgets',
      icon: 'Approval'
    }
  },
  {
    urlPath: '/featured/list',
    component: lazy(async () => import('pages/campaigns/FeaturedList')),
    navLink: {
      navSectionId: 'campaigns',
      name: 'Destaques',
      icon: 'Basket'
    }
  },
  {
    urlPath: '/customers',
    component: lazy(async () => import('pages/operations/CustomerFinderPage')),
    navLink: {
      navSectionId: 'operations',
      name: 'Usuarios',
      icon: 'FilePerson'
    }
  },
  {
    urlPath: '/purchases',
    component: lazy(async () => import('pages/operations/PurchaseFinderPage')),
    navLink: {
      navSectionId: 'operations',
      name: 'Purchases',
      icon: 'ShoppingCart'
    }
  },
  {
    urlPath: '/challenges',
    component: lazy(async () => import('pages/operations/ChallengeListPage')),
    navLink: {
      navSectionId: 'operations',
      name: 'Contestações',
      icon: 'Approval'
    }
  },
  {
    urlPath: '/withdraws',
    component: lazy(async () => import('pages/withdrawal/WithdrawalPage')),
    navLink: {
      navSectionId: 'payments',
      name: 'Resgates',
      icon: 'AttachMoney'
    }
  }
]
