import React from 'react'
import { Link } from 'react-router-dom'
import Icon from 'components/legacy/Icon'
import AsideLogo from 'components/layout/AsideLogo'

const AsideBrand: React.FC<{ asideStatus: boolean, setAsideStatus: (value: boolean) => void }> = (props) => {
  const { asideStatus, setAsideStatus } = props
  return (
    <div className='brand'>
      <div className='brand-logo'>
        <h1 className='brand-title'>
          <Link to='/' aria-label='Logo'>
            <AsideLogo height={30} width={50}/>
            <span className='brand-description'>Backoffice</span>
          </Link>
        </h1>
      </div>
      <button
        type='button'
        className='btn brand-aside-toggle'
        aria-label='Toggle Aside'
        onClick={() => setAsideStatus(!asideStatus)}>
        <Icon icon='FirstPage' className='brand-aside-toggle-close'/>
        <Icon icon='LastPage' className='brand-aside-toggle-open'/>
      </button>
    </div>
  )
}

export default AsideBrand
