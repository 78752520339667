import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Page404 from 'pages/Page404'
import { routes } from 'AppRoutes'
import FallbackPage from '../basic/FallbackPage'
import * as Sentry from '@sentry/react'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
const ContentRouter: React.FC = () => {
  return (
    <main className='content'>
      <Suspense fallback={<FallbackPage/>}>
        <SentryRoutes>
          {routes.map((page) => {
            const LazyPage = page.component
            return <Route
              key={page.urlPath}
              path={page.urlPath}
              element={<LazyPage/>}/>
          })}
          <Route path='*' element={<Page404/>}/>
        </SentryRoutes>
      </Suspense>
    </main>
  )
}

export default ContentRouter
